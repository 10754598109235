import axios from '@/common/axios'
import qs from 'qs'

export function addAnnual (data) {
  return axios({
    method: 'post',
    url: '/annual/add',
    data: qs.stringify(data)
  })
}

export function deleteAnnual (id) {
  return axios({
    method: 'delete',
    url: '/annual/delete/' + id
  })
}

export function updateAnnual (data) {
  return axios({
    method: 'put',
    url: '/annual/update',
    data: qs.stringify(data)
  })
}

export function updateAnnualManagement (data) {
  return axios({
    method: 'put',
    url: '/annual/management/update',
    data: qs.stringify(data)
  })
}

export function updateAnnualGeneral (data) {
  return axios({
    method: 'put',
    url: '/annual/general/update',
    data: qs.stringify(data)
  })
}

export function selectAnnualInfo (id) {
  return axios({
    method: 'get',
    url: '/annual/info/' + id
  })
}

export function selectAnnualList (query) {
  return axios({
    method: 'get',
    url: '/annual/list',
    params: query
  })
}
