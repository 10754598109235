<template>
  <div id="annual">
    <el-dialog
      :title="annualFormTitle"
      width="1000px"
      :visible.sync="annualDialogVisible"
      :close-on-click-modal="false"
      @close="annualDialogClose"
    >
      <el-form
        ref="annualFormRef"
        :model="annualForm"
        :rules="annualFormRules"
        label-position="right"
        label-width="160px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="企业名称" prop="supplierId">
              <el-select
                v-model="annualForm.supplierId"
                placeholder="请选择企业"
                :disabled="checkRole(['GUANDAI']) || checkRole(['ZJL'])"
                @change="supplierChange"
              >
                <el-option
                  v-for="item in supplierList"
                  :key="item.id"
                  :label="item.supplierName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productList">
              <el-select
                v-model="annualForm.productList"
                placeholder="请选择产品名称"
                multiple
                :disabled="true"
              >
                <el-option
                  v-for="item in productList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="供货时间" prop="ghsj">
              <el-input
                v-model="annualForm.ghsj"
                placeholder="请输入供货时间"
                clearable
                :readonly="annualFormTitle === '年度供方评价详情'"
                :disabled="checkRole(['GUANDAI']) || checkRole(['ZJL'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="合格率（%）" prop="hgl">
              <el-input
                v-model="annualForm.hgl"
                placeholder="请输入合格率"
                clearable
                :readonly="annualFormTitle === '年度供方评价详情'"
                :disabled="checkRole(['GUANDAI']) || checkRole(['ZJL'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="符合国家法规、规章规定要求的证照">
              <el-scrollbar style="height: 95.2px">
                <el-upload
                  ref="uploadRef"
                  action=""
                  :http-request="handleHttp"
                  :on-remove="handleRemove"
                  :file-list="fileList"
                  multiple
                  :disabled="annualFormTitle === '年度供方评价详情' || checkRole(['GUANDAI']) || checkRole(['ZJL'])"
                >
                  <el-button
                    size="small"
                    type="primary"
                    :disabled="checkRole(['GUANDAI']) || checkRole(['ZJL'])"
                  >
                    点击上传
                  </el-button>
                </el-upload>
              </el-scrollbar>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="厂房、设备及环境情况" prop="cfsb">
              <el-input
                v-model="annualForm.cfsb"
                type="textarea"
                :rows="4"
                :readonly="annualFormTitle === '年度供方评价详情'"
                :disabled="checkRole(['GUANDAI']) || checkRole(['ZJL'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="生产及交货能力" prop="scjh">
              <el-input
                v-model="annualForm.scjh"
                type="textarea"
                :rows="4"
                :readonly="annualFormTitle === '年度供方评价详情'"
                :disabled="checkRole(['GUANDAI']) || checkRole(['ZJL'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="能承担的质量责任及保证产品质量的能力" prop="cpzl">
              <el-input
                v-model="annualForm.cpzl"
                type="textarea"
                :rows="4"
                :readonly="annualFormTitle === '年度供方评价详情'"
                :disabled="checkRole(['GUANDAI']) || checkRole(['ZJL'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="售后服务及其它" prop="shfw">
              <el-input
                v-model="annualForm.shfw"
                type="textarea"
                :rows="3"
                :readonly="annualFormTitle === '年度供方评价详情'"
                :disabled="checkRole(['GUANDAI']) || checkRole(['ZJL'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结论" prop="jl">
              <el-input
                v-model="annualForm.jl"
                type="textarea"
                :rows="3"
                :readonly="annualFormTitle === '年度供方评价详情'"
                :disabled="!checkRole(['GUANDAI'])"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准" prop="pzrjg">
              <el-radio-group v-model="annualForm.pzrjg" :disabled="!checkRole(['ZJL'])">
                <el-radio :label="1">
                  同意
                </el-radio>
                <el-radio :label="2">
                  不同意
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="annualDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="annualFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-dialog
      id="downloadTable"
      title="下载文件"
      width="500px"
      :visible.sync="downloadDialogVisible"
      :close-on-click-modal="false"
    >
      <el-table
        :data="downloadList"
        row-key="id"
        :cell-style="{padding: '6px'}"
        :header-cell-style="{background: '#f8f8f9'}"
      >
        <el-table-column prop="name" label="文件名称" />
        <el-table-column label="操作" align="center" width="100">
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-download"
              size="small"
              @click.stop="handleDownload(scope.$index, scope.row)"
            >
              下载
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer">
        <el-button @click="downloadDialogVisible = false">
          取 消
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="企业名称">
        <el-input v-model="searchForm.qymc" placeholder="请输入企业名称" clearable />
      </el-form-item>
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.cpmc" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="annualPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="qymc" label="企业名称" />
      <el-table-column prop="cpmc" label="产品名称" />
      <el-table-column prop="ghsj" label="供货时间" />
      <el-table-column prop="hgl" label="合格率（%）" />
      <el-table-column prop="jl" label="结论" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <!-- <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button> -->
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
          <el-button
            type="text"
            icon="el-icon-download"
            size="small"
            @click.stop="openDownload(scope.$index, scope.row)"
          >
            下载
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="annualPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addAnnual,
  deleteAnnual,
  updateAnnual,
  updateAnnualManagement,
  updateAnnualGeneral,
  selectAnnualInfo,
  selectAnnualList
} from '@/api/purchase/annual'
import { selectSupplierInfo, selectSupplierList } from '@/api/purchase/supplier'
import { upload, download } from '@/api/main/file'
import { saveAs } from 'file-saver'

export default {
  data () {
    return {
      annualDialogVisible: false,
      annualFormTitle: '',
      annualForm: {
        id: '',
        supplierId: '',
        qymc: '',
        productList: [],
        productId: '',
        cpmc: '',
        ghsj: '',
        hgl: '',
        zz: '',
        zzUrl: '',
        cfsb: '',
        scjh: '',
        cpzl: '',
        shfw: '',
        jl: '',
        pzrjg: ''
      },
      annualFormRules: {
        supplierId: [
          {
            required: true,
            message: '企业名称不能为空',
            trigger: ['blur', 'change']
          }
        ],
        productList: [
          {
            required: true,
            message: '产品名称不能为空',
            trigger: ['blur', 'change']
          }
        ]
      },
      annualPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        qymc: '',
        cpmc: ''
      },
      supplierList: [],
      productList: [],
      fileList: [],
      downloadDialogVisible: false,
      downloadList: []
    }
  },
  created () {
    selectSupplierList().then(res => {
      this.supplierList = res.list
    })
    selectAnnualList(this.searchForm).then(res => {
      this.annualPage = res
    })
  },
  methods: {
    annualDialogClose () {
      this.$refs.uploadRef.clearFiles()
      this.$refs.annualFormRef.resetFields()
      this.fileList = []
    },
    annualFormSubmit () {
      if (this.annualFormTitle === '年度供方评价详情') {
        this.annualDialogVisible = false
        return
      }
      this.$refs.annualFormRef.validate(async valid => {
        if (valid) {
          this.annualForm.zzUrl = this.fileList.map(file => file.path).join(',')
          this.annualForm.zz = this.fileList.map(file => file.name).join(',')
          if (this.annualFormTitle === '新增年度供方评价') {
            await addAnnual(this.annualForm)
          } else if (this.annualFormTitle === '修改年度供方评价') {
            if (this.checkRole(['GUANDAI'])) {
              await updateAnnualManagement(this.annualForm)
            } else if (this.checkRole(['ZJL'])) {
              await updateAnnualGeneral(this.annualForm)
            } else {
              await updateAnnual(this.annualForm)
            }
          }
          this.annualPage = await selectAnnualList(this.searchForm)
          this.annualDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.annualFormTitle = '新增年度供方评价'
      this.annualDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteAnnual(row.id)
        if (this.annualPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.annualPage = await selectAnnualList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.annualFormTitle = '修改年度供方评价'
      this.annualDialogVisible = true
      selectAnnualInfo(row.id).then(res => {
        this.annualForm.id = res.id
        this.annualForm.supplierId = res.supplierId
        this.annualForm.qymc = res.qymc
        this.productList = res.productList
        this.annualForm.productList = res.productList.map(item => item.id)
        this.annualForm.productId = res.productList.map(item => item.id).join(',')
        this.annualForm.cpmc = res.productList.map(item => item.name).join(',')
        this.annualForm.ghsj = res.ghsj
        this.annualForm.hgl = res.hgl
        if (res.zzUrl) {
          const zzUrl = res.zzUrl.split(',')
          const zz = res.zz.split(',')
          this.fileList = []
          for (let i = 0; i < zzUrl.length; i++) {
            this.fileList.push({
              name: zz[i],
              path: zzUrl[i]
            })
          }
        }
        this.annualForm.cfsb = res.cfsb
        this.annualForm.scjh = res.scjh
        this.annualForm.cpzl = res.cpzl
        this.annualForm.shfw = res.shfw
        this.annualForm.jl = res.jl
        this.annualForm.pzrjg = res.pzrjg
      })
    },
    handleInfo (index, row) {
      this.annualFormTitle = '年度供方评价详情'
      this.annualDialogVisible = true
      selectAnnualInfo(row.id).then(res => {
        this.annualForm.id = res.id
        this.annualForm.supplierId = res.supplierId
        this.annualForm.qymc = res.qymc
        this.productList = res.productList
        this.annualForm.productList = res.productList.map(item => item.id)
        this.annualForm.productId = res.productList.map(item => item.id).join(',')
        this.annualForm.cpmc = res.productList.map(item => item.name).join(',')
        this.annualForm.ghsj = res.ghsj
        this.annualForm.hgl = res.hgl
        if (res.zzUrl) {
          const zzUrl = res.zzUrl.split(',')
          const zz = res.zz.split(',')
          this.fileList = []
          for (let i = 0; i < zzUrl.length; i++) {
            this.fileList.push({
              name: zz[i],
              path: zzUrl[i]
            })
          }
        }
        this.annualForm.cfsb = res.cfsb
        this.annualForm.scjh = res.scjh
        this.annualForm.cpzl = res.cpzl
        this.annualForm.shfw = res.shfw
        this.annualForm.jl = res.jl
        this.annualForm.pzrjg = res.pzrjg
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectAnnualList(this.searchForm).then(res => {
        this.annualPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectAnnualList(this.searchForm).then(res => {
        this.annualPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectAnnualList(this.searchForm).then(res => {
        this.annualPage = res
      })
    },
    supplierChange (id) {
      selectSupplierInfo(id).then(res => {
        this.annualForm.supplierId = res.id
        this.annualForm.qymc = res.supplierName
        this.productList = res.productList
        this.annualForm.productList = res.productList.map(item => item.id)
        this.annualForm.productId = res.productList.map(item => item.id).join(',')
        this.annualForm.cpmc = res.productList.map(item => item.name).join(',')
      })
    },
    handleHttp (file) {
      const data = new FormData()
      data.append('file', file.file)
      upload(data).then(res => {
        this.fileList.push({
          name: file.file.name,
          path: res,
          uid: file.file.uid
        })
      })
    },
    handleRemove (file, fileList) {
      this.fileList = fileList
    },
    openDownload (index, row) {
      selectAnnualInfo(row.id).then(res => {
        this.downloadList = []
        let id = 1
        if (res.zzUrl) {
          const zzUrl = res.zzUrl.split(',')
          const zz = res.zz.split(',')
          this.fileList = []
          for (let i = 0; i < zzUrl.length; i++) {
            this.downloadList.push({
              id: id++,
              name: zz[i],
              path: zzUrl[i]
            })
          }
        }
        this.downloadDialogVisible = true
      })
    },
    handleDownload (index, row) {
      download({ fileName: row.path }).then(res => {
        saveAs(res, row.name)
      })
    }
  }
}
</script>

<style>
#annual .el-scrollbar .el-scrollbar__wrap {
  overflow-x: hidden;
}
#annual #downloadTable .el-dialog__body {
  padding: 0px 20px 20px 20px
}
</style>
